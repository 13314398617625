import MiniSignal, { MiniSignalBinding } from "mini-signals";
interface Events {
  [key: string]: MiniSignal;
}
const events: Events = {};
const eventExists = (eventName: string): boolean => {
  return events[eventName] && events[eventName] instanceof MiniSignal;
};

export const eventDispatcher = {
  getEvents: (): Events => {
    return events;
  },
  getHandlers: (eventName: string, exists = false): boolean | MiniSignalBinding[] => {
    if (!eventExists(eventName)) {
      throw new Error("event doesn't exists");
    }
    return events[eventName].handlers(exists);
  },
  off: (eventName: string, callback: MiniSignalBinding): void => {
    if (!eventExists(eventName)) {
      throw new Error("event doesn't exists");
    }
    events[eventName].detach(callback);
  },
  offAll: (eventName: string): MiniSignal => {
    if (!eventExists(eventName)) {
      throw new Error("event doesn't exists");
    }
    return events[eventName].detachAll();
  },
  on: (eventName: string, callback: (params?: any) => void): MiniSignalBinding => {
    if (!eventExists(eventName)) {
      throw new Error("event doesn't exists");
    }
    return events[eventName].add(callback);
  },
  once: (eventName: string, callback: () => void): MiniSignalBinding => {
    if (!eventExists(eventName)) {
      throw new Error("event doesn't exists");
    }
    return events[eventName].once(callback);
  },
  set: (eventName: string): MiniSignal => {
    return (events[eventName] = new MiniSignal());
  },
  trigger: (eventName: string, params = {}): boolean => {
    if (!eventExists(eventName)) {
      throw new Error("event doesn't exists");
    }
    return events[eventName].dispatch(params);
  },
};
