const routes = {
  app: {
    home: {
      path: "/",
      priority: 1,
      changefreq: "monthly",
    },
    guidePerso: {
      path: "/votre-guide-personnalise",
      priority: 1,
      changefreq: "monthly",
    },
    checkoutGuidePerso: {
      path: "/checkout/guide-personnalise",
      priority: 0.2,
      changefreq: "monthly",
    },
    guides: {
      path: "/les-guides-de-marques-ethiques",
      priority: 1,
      changefreq: "monthly",
    },
    about: {
      path: "/a-propos",
      priority: 0.8,
      changefreq: "monthly",
    },
    parcours: {
      decouverte: {
        step1: {
          path: "/parcours/decouverte/etape-1",
          priority: 0.8,
          changefreq: "monthly",
        },
        step2a: {
          path: "/parcours/decouverte/etape-2a",
          priority: 0.8,
          changefreq: "monthly",
        },
        step2b: {
          path: "/parcours/decouverte/etape-2b",
          priority: 0.8,
          changefreq: "monthly",
        },
      },
      demande: {
        guidePerso: {
          path: "/parcours/demande/guide-personnalise",
          priority: 0.8,
          changefreq: "monthly",
        },
      },
    },
  },
};

module.exports = { routes };
