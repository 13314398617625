import cx from "classnames";
import { Link } from "gatsby";
import { MiniSignalBinding } from "mini-signals";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { eventDispatcher } from "../../utils/eventDispatcher";
import { Logo } from "../Logo";
import * as styles from "./index.module.scss";
const { routes } = require("../../routes");

export const Header: FC = () => {
  const clickDoNotRef = useRef<MiniSignalBinding>();
  const [isMobileOpen, setIsMobileOpen] = useState<boolean>(false);
  const [disableBg, setDisableBg] = useState<boolean>(false);

  const onDoNotClick = React.useCallback((params: { disableBg: boolean }) => {
    setDisableBg(params.disableBg);
  }, []);

  useEffect(() => {
    clickDoNotRef.current = eventDispatcher.on(
      "Click:DoNotClick",
      onDoNotClick,
    );

    return () => {
      if (clickDoNotRef.current) {
        eventDispatcher.off("Click:DoNotClick", clickDoNotRef.current);
      }
    };
  }, [onDoNotClick]);
  return (
    <header className={styles.header}>
      <div
        className={cx(styles.headerContent, { [styles.disableBg]: disableBg })}
      >
        <div className={styles.logoContainer}>
          <Link
            to="/"
            onClick={() => {
              setIsMobileOpen(false);
            }}
          >
            <Logo />
          </Link>
        </div>
        <div className={styles.navListContainer}>
          <div className={styles.burgerContainer}>
            <div
              className={cx(styles.burger, { [styles.open]: isMobileOpen })}
              onClick={() => {
                setIsMobileOpen(!isMobileOpen);
              }}
            >
              <span className={cx(styles.line, styles.top)}></span>
              <span className={cx(styles.line, styles.middle)}></span>
              <span className={cx(styles.line, styles.bottom)}></span>
            </div>
          </div>
          <div className={styles.navListContent}>
            <div
              className={cx(styles.navListInnerContent, {
                [styles.open]: isMobileOpen,
              })}
            >
              <ul className={styles.navList}>
                <li>
                  <Link
                    to={routes.app.guides.path}
                    className={styles.navItemLink}
                    activeClassName={styles.active}
                    onClick={() => {
                      setIsMobileOpen(false);
                    }}
                  >
                    Les guides ready-to-go
                  </Link>
                </li>
                <li>
                  <Link
                    to={routes.app.guidePerso.path}
                    className={styles.navItemLink}
                    activeClassName={styles.active}
                    onClick={() => {
                      setIsMobileOpen(false);
                    }}
                  >
                    Votre guide personnalisé
                  </Link>
                </li>
                <li>
                  <Link
                    to={routes.app.about.path}
                    className={styles.navItemLink}
                    activeClassName={styles.active}
                    onClick={() => {
                      setIsMobileOpen(false);
                    }}
                  >
                    Qui suis-je ?
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
