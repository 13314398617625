import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import React, { FC } from "react";
import { SiInstagram } from "react-icons/si";
import { Logo } from "../Logo";
import * as styles from "./index.module.scss";

export const Footer: FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className={styles.socialLinksContainer}>
          <OutboundLink
            href="https://www.instagram.com/constant.personalshopper/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiInstagram />
          </OutboundLink>
        </div>
      </div>
    </footer>
  );
};
