/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FC, PropsWithChildren, useEffect } from "react";

import { eventDispatcher } from "../utils/eventDispatcher";
import { Footer } from "./footer";
import { Header } from "./header";

import "../styles/main.scss";

eventDispatcher.set("_update");
eventDispatcher.set("_resize");
eventDispatcher.set("Click:DoNotClick");

const Layout: FC<PropsWithChildren<unknown>> = ({
  children,
}: PropsWithChildren<unknown>) => {
  const requestRef = React.useRef(0);

  useEffect(() => {
    requestRef.current = requestAnimationFrame(_update);
    window.addEventListener("resize", _resize);
    _resize();

    return () => {
      window.removeEventListener("resize", _resize);
      cancelAnimationFrame(requestRef.current);
    };
  }, []);

  const _update = () => {
    const _y = window.scrollY || window.pageYOffset;
    const wH =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const wW =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    eventDispatcher.trigger("_update", { _y, wH, wW });
    requestRef.current = requestAnimationFrame(_update);
  };

  const _resize = () => {
    const wW =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    const wH =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    eventDispatcher.trigger("_resize", { wW, wH });
  };

  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
